import React from 'react'
import { useDispatch } from 'react-redux'
import { login } from '../store/authSlice'
import logo from '../assets/images/logo1.png'

export default function Login() {

    const dispatch = useDispatch()

    const onSubmit = (event) => {
        event.preventDefault()

        const form = event.target

        if (form.checkValidity()) {
            dispatch(login({
                comsumerKey: form['comsumerKey'].value,
                comsumerSecret: form['comsumerSecret'].value
            }))
        }

        form.classList.add("was-validated")
    }

    return (
        <div className='bg-primary bg-opacity-10 w-100 h-100 d-flex align-items-center justify-content-center'>

            <form className="card p-3 shadow-sm mx-3" onSubmit={onSubmit} noValidate>

                <img className='w-50 mx-auto py-5' src={logo} alt="" />

                <div className="form-floating mb-3">
                    <input className="form-control" id="comsumerKey" name='comsumerKey' placeholder="name@example.com" required />
                    <label htmlFor="comsumerKey">Comsumer Key</label>
                </div>

                <div className="form-floating mb-3">
                    <input className="form-control" id="comsumerSecret" name='comsumerSecret' placeholder="Password" required />
                    <label htmlFor="comsumerSecret">Comsumer Secret</label>
                </div>

                <button className='btn btn-primary text-white'>Login</button>

            </form>


        </div>
    )
}
