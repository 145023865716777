import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const api = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://pizza-stop.co.uk/wp-json/",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token
            if (token) headers.set('Authorization', 'Basic ' + token)
            return headers
        }
    }),
    endpoints: (builder) => ({
        orders: builder.query({ query: (page) => `wc/v3/orders?page=${page}`, keepUnusedDataFor: 0 }),
        processingOrders: builder.query({ query: (page) => `wc/v3/orders?per_page=100&status=processing`, keepUnusedDataFor: 0 }),
        todayOrders: builder.query({ query: (date) => `wc/v3/orders?after=${date}T00:00:00&before=${date}T23:59:59`, keepUnusedDataFor: 0 }),
        order: builder.query({ query: (id) => `wc/v3/orders/${id}`, keepUnusedDataFor: 0 }),
        orderReport: builder.query({ query: () => `wc/v3/reports/orders/totals`, keepUnusedDataFor: 0 }),
        complete: builder.mutation({ query: (id) => ({ url: `wc/v3/orders/${id}`, method: "PUT", body: { status: "completed" } }) }),
        cancel: builder.mutation({ query: (id) => ({ url: `wc/v3/orders/${id}`, method: "PUT", body: { status: "cancelled" } }) }),
        fcm: builder.mutation({ query: (token) => ({ url: `fcm/v1/save-token`, method: "POST", body: { token } }) }),
        updateDeliveryTime: builder.mutation({ query: ({ id, meta_data }) => ({ url: `wc/v3/orders/${id}`, method: "PUT", body: { meta_data } }) }),

    })
})

export const {
    useOrdersQuery, useOrderQuery,
    useCancelMutation, useCompleteMutation,
    useProcessingOrdersQuery, useOrderReportQuery,
    useTodayOrdersQuery, useFcmMutation, useUpdateDeliveryTimeMutation
} = api


