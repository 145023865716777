import React, { useEffect, useState } from 'react'
import { useOrderReportQuery, useOrdersQuery } from '../apis/api'
import { useDispatch } from 'react-redux'
import { logout } from '../store/authSlice'
import Loading from '../components/Loading'
import Pagination from '../components/Pagination'
import MapOrders from '../components/MapOrders'
// import { BiSearchAlt2 } from 'react-icons/bi';

export default function Orders() {

  const [page, setPage] = useState(1)
  const response = useOrdersQuery(page)
  const report = useOrderReportQuery()
  const dispatch = useDispatch()
  
  const totalPages = (data) => {
    let total = 0
    data?.map((item) => {
      total = total + item.total
    })
    return total/10
  }


  useEffect(() => {
    response.refetch()
    report.refetch()
  }, []) 

  
  if (response.isLoading) return <Loading />
  if (response.isFetching) return <Loading />
  if (response.isError) dispatch(logout())
  if (report.isLoading) return <Loading />
  if (report.isError) dispatch(logout())



  return (
    <div className="container py-3">

      {/* <div className="row">
        <div className="col">
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Search order by ID" />
            <button className="btn btn-primary text-white" type="button" id="button-addon2">
              <BiSearchAlt2 style={{ height: 25, width: 25 }} />
            </button>
          </div>
        </div>
      </div> */}

      <MapOrders orders={response.data} />

      <Pagination page={page} totalPages={totalPages(report.data)} setPage={setPage} />

    </div>
  )
}
