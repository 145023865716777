import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Navbar from './Navbar'
import NewOrderModal from '../NewOrderModal'
import FCM from '../FCM'
import { useProcessingOrdersQuery } from '../../apis/api'
import soundFile from "../../assets/audio/sound.wav"
import notificationSound from '../../assets/notificationSound/cart_add.wav'

export default function Layout() {

    const sound = new Audio(soundFile);
    const { pathname } = useLocation()
    const [orderId, setOrderId] = useState(null)
    const [lastOrderIds, setLastOrderIds] = useState([])
    const [timer, setTimer] = useState(null)

    const response = useProcessingOrdersQuery(undefined, { pollingInterval: 10000 })
    const stop = () => {
        if (timer) {
            clearInterval(timer)
            setTimer(null)
        }
    }

    const playSound = () => {
        try {
            const t = setInterval(() => {
                sound.play();
            }, 2000);
            setTimer(t)

        }
        catch (error) { console.log(error) }
    }

    useEffect(() => {
        if (sound) sound.load()
    }, [])


    useEffect(() => {
        if (response.data) {
            const newOrder = response.data.find((item) => !lastOrderIds.includes(item.id))
            if (newOrder) {
                playSound()
                setOrderId(newOrder.id)
                document.getElementById("newOrderModalButton").click()
                setLastOrderIds(response.data.map((order) => order.id))
            }
            else setLastOrderIds(response.data.map((order) => order.id))
        }
    }, [response.data, response.isSuccess])


    useEffect(() => {
        stop()
        const classList = document.body.classList
        if (classList.contains("modal-open")) classList.remove("modal-open")

        const backdrops = document.getElementsByClassName("modal-backdrop")
        for (const backdrop of backdrops) backdrop.remove()
    }, [pathname])

    return (
        <div className='w-100 h-100 overflow-hidden d-flex flex-column'>
            <main className='flex-grow-1 overflow-auto'>
                <Outlet />
            </main>
            <Navbar />

            <NewOrderModal orderId={orderId} stop={stop} />
        </div>
    )
}
