import React, { useRef, useState } from 'react'
import { useTodayOrdersQuery } from '../apis/api'
import Loading from '../components/Loading'
import { logout } from '../store/authSlice'
import { useDispatch } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { BiSolidPrinter } from 'react-icons/bi'

export default function DailyReport() {

    const [date, setDate] = useState(new Date().toISOString().split('T')[0])
    const componentRef = useRef()
    const dispatch = useDispatch()
    const response = useTodayOrdersQuery(date)

    const getCurrencySymbol = (orders) => orders[0].currency_symbol

    const getReport = (orders) => {
        let card_total_orders = 0
        let card_sub_total = 0

        let cash_total_orders = 0
        let cash_sub_total = 0

        let collection_total_orders = 0
        let collection_sub_total = 0

        let delivery_total_orders = 0
        let delivery_sub_total = 0

        let total_orders = 0
        let total = 0

        orders.map((order) => {
            if (order.shipping_lines[0].method_title === "Delivery") {
                delivery_total_orders++
                delivery_sub_total = delivery_sub_total + parseFloat(order.total)
            }
            else {
                collection_total_orders++
                collection_sub_total = collection_sub_total + parseFloat(order.total)
            }

            if (order.payment_method === "cod") {
                cash_total_orders++
                total_orders++
                cash_sub_total = cash_sub_total + parseFloat(order.total)
                total = total + parseFloat(order.total)
            }
            else {
                card_total_orders++
                total_orders++
                card_sub_total = card_sub_total + parseFloat(order.total)
                total = total + parseFloat(order.total)
            }
        })

        return {
            card_total_orders, card_sub_total,
            cash_total_orders, cash_sub_total,
            collection_total_orders, collection_sub_total,
            delivery_total_orders, delivery_sub_total,
            total_orders, total
        }
    }

    if (response.isLoading) return <Loading />
    if (response.isFetching) return <Loading />
    if (response.isError) dispatch(logout())

    const orders = response.data

    const content = (orders) => {
        const currency_symbol = getCurrencySymbol(orders)
        // const date = getTodayDate(orders)
        const report = getReport(orders)

        return (
            <>
                <div className="row mb-3">
                    <div className="col">
                        <ReactToPrint
                            trigger={() => <button className="btn btn-primary py-2 px-3 d-flex flex-column align-items-center ms-auto text-white" >
                                <BiSolidPrinter style={{ height: 25, width: 25 }} />
                                Print
                            </button>}
                            content={() => componentRef.current}
                        />
                    </div>
                </div>

                <div className="row row-cols-1 g-3">

                    <div className="col">
                        <div className='card p-3 shadow-sm'>
                            <h5>Delivery</h5>
                            <div>No. of Orders: {report.delivery_total_orders}</div>
                            <div>Sub Total: {currency_symbol + parseFloat(report.delivery_sub_total).toFixed(2)}</div>
                        </div>
                    </div>

                    <div className="col">
                        <div className='card p-3 shadow-sm'>
                            <h5>Collection</h5>
                            <div>No. of Orders: {report.collection_total_orders}</div>
                            <div>Sub Total: {currency_symbol + parseFloat(report.collection_sub_total).toFixed(2)}</div>
                        </div>
                    </div>

                    <div className="col">
                        <div className='card p-3 shadow-sm'>
                            <h5>Card</h5>
                            <div>No. of Orders: {report.card_total_orders}</div>
                            <div>Sub Total: {currency_symbol + parseFloat(report.card_sub_total).toFixed(2)}</div>
                        </div>
                    </div>

                    <div className="col">
                        <div className='card p-3 shadow-sm'>
                            <h5>Cash</h5>
                            <div>No. of Orders: {report.cash_total_orders}</div>
                            <div>Sub Total: {currency_symbol + parseFloat(report.cash_sub_total).toFixed(2)}</div>
                        </div>
                    </div>

                    <div className="col">
                        <div className='card p-3 shadow-sm'>
                            <h5>Grand Total</h5>
                            <div>No. of Orders: {report.total_orders}</div>
                            <div>Total: {currency_symbol + parseFloat(report.total).toFixed(2)}</div>
                        </div>
                    </div>

                </div>

                <div className="d-none">
                    <div
                        className='container py-2 fs-12 fw-bold'
                        ref={componentRef}
                        style={{ width: '57mm' }}
                    >

                        <div className="row mb-3 pb-2 border-bottom border-dark border-3">
                            <div className="col text-center">
                                <h3>Daily report</h3>
                                <div>{date}</div>
                            </div>
                        </div>

                        <div className="row row-cols-1 g-3">

                            <div className="col">
                                <h5>Delivery</h5>
                                <div>No. of Orders: {report.delivery_total_orders}</div>
                                <div>Sub Total: {currency_symbol + parseFloat(report.delivery_sub_total).toFixed(2)}</div>
                            </div>

                            <div className="col pb-2 border-bottom border-dark border-2">
                                <h5>Collection</h5>
                                <div>No. of Orders: {report.collection_total_orders}</div>
                                <div>Sub Total: {currency_symbol + parseFloat(report.collection_sub_total).toFixed(2)}</div>
                            </div>

                            <div className="col">
                                <h5>Card</h5>
                                <div>No. of Orders: {report.card_total_orders}</div>
                                <div>Sub Total: {currency_symbol + parseFloat(report.card_sub_total).toFixed(2)}</div>
                            </div>

                            <div className="col pb-2 border-bottom border-dark border-2">
                                <h5>Cash</h5>
                                <div>No. of Orders: {report.cash_total_orders}</div>
                                <div>Sub Total: {currency_symbol + parseFloat(report.cash_sub_total).toFixed(2)}</div>
                            </div>

                            <div className="col">
                                <h5>Grand Total</h5>
                                <div>No. of Orders: {report.total_orders}</div>
                                <div>Total: {currency_symbol + parseFloat(report.total).toFixed(2)}</div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }

    const contentNoOrders = () => {
        return (
            <div>
                No Order Today
            </div>
        )
    }


    return (
        <div className="container py-5">

            <div className="row mb-3">
                <div className="col text-center">
                    <h3>Daily report</h3>
                    <div className="form-floating mb-3">
                        <input type="date" className="form-control" id="floatingInput" placeholder="name@example.com" value={date} onChange={(e) => setDate(e.target.value)} />
                        <label for="floatingInput">Select Date</label>
                    </div>
                </div>
            </div>

            {orders.length > 0 ? content(orders) : contentNoOrders()}
        </div>
    )



}
