import React from 'react'
import { AiOutlineLogout } from 'react-icons/ai'
import { HiOutlineDocumentReport } from 'react-icons/hi'
import { useDispatch } from 'react-redux'
import { logout } from '../store/authSlice'
import { Link } from 'react-router-dom'
import PwaInstallButton from '../components/InstallPwaButton'

export default function Settings() {

    const dispatch = useDispatch()

    const onClick = () => dispatch(logout())

    return (
        <div className='text-center py-5 d-flex flex-column px-5'>



            <Link className='btn btn-outline-primary mb-4' type='button' to={"/dailyReport"}>
                <HiOutlineDocumentReport className='me-2 fs-1' />
                Daily Report
            </Link>

            <button className='btn btn-outline-primary mb-3' type='button' onClick={onClick}>
                <AiOutlineLogout className='me-2 fs-1' />
                Logout
            </button>
            
            <PwaInstallButton />
        </div>
    )
}
