import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import "./assets/css/global.css"
import "./assets/scss/bootstrap.scss"
import { useEffect, useState } from 'react';
import Login from './pages/Login';
import { useSelector } from 'react-redux';
import { selectAuth } from './store/authSlice';
import Orders from './pages/Orders';
import Order from './pages/Order';
import Layout from './components/layout/Layout';
import Settings from './pages/Settings';
import Home from './pages/Home';
import DailyReport from './pages/DailyReport';

function App() {

  const [orderId, setOrderId] = useState(null)

  const { isLoggedIn } = useSelector(selectAuth)
  useEffect(() => { import("bootstrap") }, [])

 

  return (
    <BrowserRouter>
      <Routes>

        {
          isLoggedIn ?
            <Route path="/" element={<Layout />}>

              <Route index element={<Home  />} />
              <Route path="settings" element={<Settings />} />
              <Route path="dailyReport" element={<DailyReport />} />

              <Route path="order">
                <Route index element={<Orders />} />
                <Route path=":id" element={<Order />} />
              </Route>
            </Route>
            :
            <Route path="*" element={<Login />} />
        }






      </Routes>



    </BrowserRouter>

  );
}

export default App;
