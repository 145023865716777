import React from 'react'
import Address from './Address';
import Items from './Items';
import Total from './Total';

const PrintPage = ({ order }) => {

    const method_title = order.shipping_lines[0].method_title
    return (
        <div className="page">
            <div className="row">
                <div className="col mb-3">
                    <h4 className='text-center mb-0'>Pizza Stop</h4>
                    <div className='text-center'>Tel: 01323725550</div>
                    <hr className='my-1' />
                    <h6 className='text-center'>{method_title}</h6>
                    <h6 className='text-center fs-14'>
                        Due {new Date(order.date_created).toDateString().substring(4, 10)} at {order.meta_data.filter(data => data.key === "delivery_time")[0]?.value}
                    </h6>
                    <h6 className='text-center fs-14'>Order Number: {order.id}</h6>
                    <hr className='my-1' />
                    <Items order={order} />
                    <hr className='my-1' />
                    <Total order={order} />
                    <hr className='my-1' />
                    <div className="fs-14 fw-normal">Customer Details:</div>
                    <Address address={order.shipping} displayAddress={method_title === "Delivery"} />
                    <div className='fs-14 mb-2 fw-normal'>To contact customer call:<br></br> <strong>{order.billing.phone}</strong></div>
                    <div className='text-center fs-14 fw-bold'>www.pizza-stop.co.uk</div>
                </div>
            </div>
        </div>
    )
}

export default PrintPage