import React from 'react'
import { BiSolidCloudDownload } from 'react-icons/bi'
import logo from '../assets/images/logo1.png'

export default function Loading() {
    return (
        <div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center text-danger">
            <div className="floater text-center">
                {/* <BiSolidCloudDownload style={{ height: 150, width: 150 }} /> */}
                <img className='w-75'  src={logo} alt="" />
                <h4 className='text-center'>Loading...</h4>
            </div>
        </div>
    )
}
