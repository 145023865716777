import React from 'react'

export default function Tag({status}) {

    const getClass = (status) => {
        const className = "px-3 shadow-sm rounded-pill text-white text-capitalize"
        switch (status) {
            case "processing": return className + " bg-warning"
            case "cancelled": return className + " bg-danger"
            case "completed": return className + " bg-success"
            default: return className + " bg-primary"
        }
    }

    return (
       <div>
         <span className={getClass(status)}>{status}</span>
       </div>
    )
}
