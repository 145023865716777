import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    token: null,
    isLoggedIn: false
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            state.token = btoa(`${action.payload.comsumerKey}:${action.payload.comsumerSecret}`)
            state.isLoggedIn = true
        },
        logout: (state) => {
            state.token = initialState.token
            state.isLoggedIn = initialState.isLoggedIn
        },
    },
});

export const { login, logout } = authSlice.actions;

export const selectAuth = (state) => state.auth;

export default authSlice.reducer;
