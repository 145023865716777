import React from 'react'
import { NavLink } from 'react-router-dom'
import { BiFoodMenu } from 'react-icons/bi';
import { FiSettings } from 'react-icons/fi';
import { BiSolidHomeSmile } from 'react-icons/bi';

export default function Navbar() {
    return (
        <div className='navbar-nav flex-row bg-primary navbar-dark text-center'>

            <div className="nav-item flex-grow-1">
                <NavLink className="nav-link d-flex flex-column align-items-center" to="/order" >
                    <BiFoodMenu style={{ height: 25, width: 25 }} />
                    Orders
                </NavLink>
            </div>

            <div className="nav-item flex-grow-1">
                <NavLink className="nav-link d-flex flex-column align-items-center" to="/" >
                    <BiSolidHomeSmile style={{ height: 25, width: 25 }} />
                    Home
                </NavLink>
            </div>

            <div className="nav-item flex-grow-1">
                <NavLink className="nav-link d-flex flex-column align-items-center" to="/settings" >
                    <FiSettings style={{ height: 25, width: 25 }} />
                    Settings
                </NavLink>
            </div>


        </div>
    )
}
