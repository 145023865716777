
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { BiSolidPrinter } from 'react-icons/bi';
import PrintPage from './PrintPage';

export default function Print({ order }) {

    const componentRef = useRef();

    return (
        <>
            <ReactToPrint
                trigger={() => (
                    <button className="nav-link d-flex flex-column align-items-center w-100 active">
                        <BiSolidPrinter style={{ height: 25, width: 25 }} />
                        Print
                    </button>
                )}
                content={() => componentRef.current}
            />

            <div className="d-none">
                <div className='container py-2 fs-14 fw-bold' ref={componentRef}>
                    <PrintPage order={order} />

                </div>
            </div>

            <style>
                {`
                    .page {
                        page-break-after: always;
                    }
                `}
            </style>
        </>
    )
}













