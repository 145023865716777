import React, { useEffect, useState } from 'react'
import { useProcessingOrdersQuery } from '../apis/api'
import { useDispatch } from 'react-redux'
import { logout } from '../store/authSlice'
import Loading from '../components/Loading'
import MapOrders from '../components/MapOrders'

export default function Orders() {

    const response = useProcessingOrdersQuery()
    const dispatch = useDispatch()

    if (response.isLoading) return <Loading />
    if (response.isError) dispatch(logout())

    return (
        <div className="container py-3">
            <MapOrders orders={response.data} />
        </div>
    )
}

