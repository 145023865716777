import React from 'react'

export default function Address({ address, displayAddress }) {
  return (
    <>
      <div>{address.first_name} {address.last_name}</div>
      {
        displayAddress &&
        <>
          <div>{address.address_1}</div>
          <div>{address.address_2}</div>
          <div>{address.city}</div>
          <div>{address.company}</div>
          <div>{address.state}</div>
          <div>{address.postcode}</div>
          <div>{address.phone}</div>
          <div>{address.email}</div>
        </>
      }

    </>
  )
}
