import React from 'react'

export default function Pagination({ totalPages, page, setPage }) {

    const pages = () => {

        if (page === 1) return [page, page + 1, page + 2]
        else return [page - 1, page, page + 1]
    }

    return (
        <div className="row">
            <div className="col">
                <nav className='d-flex justify-content-center'>
                    <ul className="pagination">
                        <li className={page === 1 ? "page-item disabled" : "page-item"}>
                            <button onClick={() => setPage(page - 1)} className="page-link">Previous</button>
                        </li>
                        {pages().map((pg, index) => <li key={index} className={pg === page ? "page-item active" : "page-item"}><button onClick={() => setPage(pg)} className="page-link" href="#">{pg}</button></li>)}
                        <li className={page === totalPages ? "page-item disabled" : "page-item"}>
                            <button onClick={() => setPage(page + 1)} className="page-link" href="#">Next</button>
                        </li>
                    </ul>
                </nav>

            </div>
        </div>
    )
}
