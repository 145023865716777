import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { api } from '../apis/api'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import authReducer from './authSlice';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'pizza5',
    storage,
    whitelist: ['auth'] // only 'auth' will be persisted
};

const reducer = combineReducers({
    [api.reducerPath]: api.reducer,
    auth: authReducer
})

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware)
})

setupListeners(store.dispatch)
