import React from 'react'
import { useOrderQuery } from '../apis/api'
import { useDispatch } from 'react-redux'
import { logout } from '../store/authSlice'
import { useParams } from 'react-router-dom'
import Items from '../components/Items'
import Total from '../components/Total'
import Address from '../components/Address'
import ActionBar from '../components/ActionBar'
import Loading from '../components/Loading'

export default function Order() {

  const { id } = useParams()
  const response = useOrderQuery(id)
  const dispatch = useDispatch()

  if (response.isLoading) return <Loading />
  if (response.isError) dispatch(logout())
  const order = response.data

  return (
    <>

      <ActionBar order={order} refetch={response.refetch} />


      <div className="container py-5 ">

        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">

          <div className="col">
            <div className="card p-3 shadow-sm">
              <h5>{order.shipping_lines[0].method_title}</h5>
            </div>
          </div>

          <div className="col">
            <div className="card p-3 shadow-sm">
              <h5>Billing</h5>
              <Address address={order.billing} displayAddress />
            </div>
          </div>

          <div className="col">
            <div className="card p-3 shadow-sm">
              <h5>Customer Details:</h5>
              <Address address={order.shipping} displayAddress />
              <div><strong>Requested for: </strong>{new Date(order.date_created).toDateString().substring(4, 10)} at {order.meta_data.filter(data => data.key === "delivery_time")[0]?.value}</div>
            </div>
          </div>

          <div className="col">
            <div className="card p-3 shadow-sm">
              <Items order={order} />
              <Total order={order} />
            </div>
          </div>

        </div>




      </div>





    </>
  )
}
