import React, { useEffect } from 'react'
import { BiSolidBadgeCheck } from 'react-icons/bi';
import { MdCancel } from 'react-icons/md';

import Print from './Print';
import { useCancelMutation, useCompleteMutation } from '../apis/api';
import Tag from './Tag';
import DeliveryTime from './DeliveryTime';
import PrintTwo from './PrintTwo';

export default function ActionBar({ order, refetch }) {

    const [complete, completeResponse] = useCompleteMutation()
    const [cancel, cancelResponse] = useCancelMutation()

    const btnClass = "nav-link d-flex flex-column align-items-center mx-auto active"

    const getClass = (status) => {
        const className = "shadow-sm text-center text-white text-capitalize"
        switch (status) {
            case "processing": return className + " bg-warning"
            case "cancelled": return className + " bg-danger"
            case "completed": return className + " bg-success"
            default: return className
        }
    }

    const handleComplete = async (id) => {

        await complete(id);

        document.getElementById('mainPrint').click();

    }

    useEffect(() => {
        if (completeResponse.isSuccess) refetch()
    }, [completeResponse.isSuccess])

    useEffect(() => {
        if (cancelResponse.isSuccess) refetch()
    }, [cancelResponse.isSuccess])

    return (
        <div className='card shadow-sm sticky-'>
            <div className='navbar-nav flex-row bg-light navbar-dar text-center'>


                <div className="nav-item flex-grow-1 text-primary border">
                    <Print order={order} />
                </div>

                {
                    order.status === 'processing'
                    &&
                    <>
                        <div className="nav-item flex-grow-1 text-danger border d-flex align-items-center">
                            {
                                cancelResponse.isLoading
                                    ?
                                    <button className={btnClass} type="button" disabled>
                                        <span className="spinner-border spinner-border" aria-hidden="true"></span>
                                        <span className="visually-hidden" role="status">Loading...</span>
                                    </button>
                                    :
                                    <button className={btnClass} type='button' onClick={() => cancel(order.id)}>
                                        <MdCancel style={{ height: 25, width: 25 }} />
                                        Cancel
                                    </button>
                            }
                        </div>

                        <div className="nav-item flex-grow-1 text-success border d-flex align-items-center">
                            {
                                completeResponse.isLoading ?
                                    <button className={btnClass} type="button" disabled>
                                        <span className="spinner-border spinner-border" aria-hidden="true"></span>
                                        <span className="visually-hidden" role="status">Loading...</span>
                                    </button>
                                    :
                                    <button className={btnClass} type='button' onClick={() => handleComplete(order.id)}>
                                        <BiSolidBadgeCheck style={{ height: 25, width: 25 }} />
                                        Complete
                                    </button>
                            }





                        </div>

                    </>
                }


            </div>

            <div className="d-flex px-3 py-2 text-center justify-content-between align-items-center">
                <h4 className='mb-00'>#{order.id}</h4>
                <Tag status={order.status} />
                <DeliveryTime order={order} refetch={refetch} />

            </div>

            <PrintTwo order={order} />


        </div>
    )
}
